
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReports3HjHUf4dntMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93MNvDmDvBgSMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editor4D8Vqs6aaIMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/blog/editor.vue?macro=true";
import { default as indexDBlJpGG7qLMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/index.vue?macro=true";
import { default as moderationigk4achhzoMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/moderation.vue?macro=true";
import { default as pendingBanslo44ZaLKsPMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/pendingBans.vue?macro=true";
import { default as profilesyRcwyKp1yzMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/profiles.vue?macro=true";
import { default as expensesiPR9wo6YPEMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexd9n8PdA6vFMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/timesheets/index.vue?macro=true";
import { default as overview5wIpSREsvAMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaiting7KRT2b087lMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingJypfmLRSYAMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/translations/missing.vue?macro=true";
import { default as userszdr23GzD4yMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/users.vue?macro=true";
import { default as apihHMj5l6xwaMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/api.vue?macro=true";
import { default as _91slug_93WXDVstUGHZMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/blog/[slug].vue?macro=true";
import { default as indexqikR1qd2i1Meta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93gN4cJgC2olMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_936PI4e024m6Meta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminqy0Mqp9e80Meta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/census/admin.vue?macro=true";
import { default as indexopQYceifcZMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/census/index.vue?macro=true";
import { default as contactkWpAgxDaGtMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/contact.vue?macro=true";
import { default as designcGouaQqnWxMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/design.vue?macro=true";
import { default as englishIONLZihDtmMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/english.vue?macro=true";
import { default as faqTdZVG0mO2uMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/faq.vue?macro=true";
import { default as inclusiveW0uh1U0aa1Meta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/inclusive.vue?macro=true";
import { default as index_45homeJUDB3LjJohMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/index-home.vue?macro=true";
import { default as index_45localepD6JPtd4RSMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/index-locale.vue?macro=true";
import { default as langswitch5zO4l2k8eRMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/langswitch.vue?macro=true";
import { default as licenseJ9Pv0TTvVwMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/license.vue?macro=true";
import { default as academicWywzD9SzEFMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/links/academic.vue?macro=true";
import { default as indexvHFOFkkzxFMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/links/index.vue?macro=true";
import { default as mediaVfZzyjXV49Meta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/links/media.vue?macro=true";
import { default as translinguisticssM7iS48DAXMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/links/translinguistics.vue?macro=true";
import { default as zineyl6vwaTJbfMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/links/zine.vue?macro=true";
import { default as nameschHf0wKRD1Meta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/names.vue?macro=true";
import { default as index3w5zYcVxakMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/nouns/index.vue?macro=true";
import { default as templatesNoLnUSBdJyMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/nouns/templates.vue?macro=true";
import { default as people2it8EofyzwMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/people.vue?macro=true";
import { default as privacyMfrUSyXnyaMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/privacy.vue?macro=true";
import { default as _91username_93TXFIG8ZUXYMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93Fc8mbQGJaTMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/profile/card-[username].vue?macro=true";
import { default as editorC5XdCTuhnqMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/profile/editor.vue?macro=true";
import { default as anyW3hLFBZ776Meta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/any.vue?macro=true";
import { default as askz5NmcIW5UUMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/ask.vue?macro=true";
import { default as avoidingoBwAG5oP0KMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/avoiding.vue?macro=true";
import { default as indexnakOFZf850Meta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/index.vue?macro=true";
import { default as mirrorsSvo1QKYnSMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/mirror.vue?macro=true";
import { default as pronounprr9kf3PuyMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcescFxydfMyLXMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/sources.vue?macro=true";
import { default as teamqzGNiew3IJMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/team.vue?macro=true";
import { default as terminologyAt1Lopu0Y3Meta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/terminology.vue?macro=true";
import { default as termsW7v4msCdUsMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/terms.vue?macro=true";
import { default as userZNvjZmPKnUMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/user.vue?macro=true";
import { default as workshopsSHlDaI8rIsMeta } from "/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/workshops.vue?macro=true";
import { default as neutratywy1pA2aUI8UMMeta } from "~/data/nouns/neutratywy.vue?macro=true";
import { default as dukatywyefwx6B0yWaMeta } from "~/data/nouns/dukatywy.vue?macro=true";
import { default as osobatywyJt6U0sHeFZMeta } from "~/data/nouns/osobatywy.vue?macro=true";
import { default as iksatywy4G8TfyCT5SMeta } from "~/data/nouns/iksatywy.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports3HjHUf4dntMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBanslo44ZaLKsPMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apihHMj5l6xwaMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93WXDVstUGHZMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexqikR1qd2i1Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93gN4cJgC2olMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_936PI4e024m6Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminqy0Mqp9e80Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexopQYceifcZMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactkWpAgxDaGtMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishIONLZihDtmMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqTdZVG0mO2uMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveW0uh1U0aa1Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45homeJUDB3LjJohMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localepD6JPtd4RSMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitch5zO4l2k8eRMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicWywzD9SzEFMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexvHFOFkkzxFMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaVfZzyjXV49Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticssM7iS48DAXMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineyl6vwaTJbfMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: nameschHf0wKRD1Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index3w5zYcVxakMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesNoLnUSBdJyMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: people2it8EofyzwMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyMfrUSyXnyaMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93TXFIG8ZUXYMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93Fc8mbQGJaTMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorC5XdCTuhnqMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyW3hLFBZ776Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askz5NmcIW5UUMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingoBwAG5oP0KMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexnakOFZf850Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorsSvo1QKYnSMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounprr9kf3PuyMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcescFxydfMyLXMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamqzGNiew3IJMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyAt1Lopu0Y3Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsW7v4msCdUsMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userZNvjZmPKnUMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsSHlDaI8rIsMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/2025-02-10--19-57-15/pages/workshops.vue")
  },
  {
    name: "nouns-neutratywy",
    path: "/neutratywy",
    component: () => import("~/data/nouns/neutratywy.vue")
  },
  {
    name: "nouns-dukatywy",
    path: "/dukatywy",
    component: () => import("~/data/nouns/dukatywy.vue")
  },
  {
    name: "nouns-osobatywy",
    path: "/osobatywy",
    component: () => import("~/data/nouns/osobatywy.vue")
  },
  {
    name: "nouns-iksatywy",
    path: "/iksatywy",
    component: () => import("~/data/nouns/iksatywy.vue")
  }
]